/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    pre: "pre",
    code: "code",
    ul: "ul",
    li: "li",
    strong: "strong",
    h3: "h3",
    h4: "h4",
    ol: "ol"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "day-4-scratchcards"
  }, "Day 4: Scratchcards"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://adventofcode.com/2023/day/4"
  }, "https://adventofcode.com/2023/day/4")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, "TL;DR: ", React.createElement(_components.a, {
    href: "https://github.com/NickyMeuleman/scrapyard/blob/main/advent_of_code/2023/solutions/src/day_04.rs"
  }, "my solution in Rust"))), "\n", React.createElement(_components.p, null, "The gondola took you up to a different sky-island (the best kind)."), "\n", React.createElement(_components.p, null, "An elf wants your help with this giant pile of scratchcards in front of them."), "\n", React.createElement(_components.p, null, "Today’s input file is a list of information on each scratchcard."), "\n", React.createElement(_components.p, null, "An example input looks like this:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt",
    title: "input.txt"
  }, "Card 1: 41 48 83 86 17 | 83 86  6 31 17  9 48 53\nCard 2: 13 32 20 16 61 | 61 30 68 82 17 32 24 19\nCard 3:  1 21 53 59 44 | 69 82 63 72 16 21 14  1\nCard 4: 41 92 73 84 69 | 59 84 76 51 58  5 54 83\nCard 5: 87 83 26 28 32 | 88 30 70 12 93 22 82 36\nCard 6: 31 18 13 56 72 | 74 77 10 23 35 67 36 11\n")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To the ", React.createElement(_components.strong, null, "left"), " of the pipe ", React.createElement(_components.code, null, "|"), " are the ", React.createElement(_components.strong, null, "winning numbers"), "."), "\n", React.createElement(_components.li, null, "To the ", React.createElement(_components.strong, null, "right"), " of the pipe ", React.createElement(_components.code, null, "|"), " are the ", React.createElement(_components.strong, null, "numbers on card"), "."), "\n"), "\n", React.createElement(_components.p, null, "In the above example, card 1 has five winning numbers (41, 48, 83, 86, and 17), and you have eight numbers (83, 86, 6, 31, 17, 9, 48, and 53).\nOf the numbers you have, four of them (48, 83, 17, and 86) are winning numbers!"), "\n", React.createElement(_components.h2, {
    id: "part-1"
  }, "Part 1"), "\n", React.createElement(_components.p, null, "Each card has a score.\nThe first winning number is worth 1 point.\nEvery matching number after that doubles your score."), "\n", React.createElement(_components.p, null, "For the first card in the example, there were 4 winning numbers."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The first match is worth 1 point"), "\n", React.createElement(_components.li, null, "The second match doubles that to 2"), "\n", React.createElement(_components.li, null, "The third match doubles that to 4"), "\n", React.createElement(_components.li, null, "The fourth match doubles that to 8"), "\n"), "\n", React.createElement(_components.p, null, "The question asks what the sum is of all card scores."), "\n", React.createElement(_components.h3, {
    id: "option-1-for-loop"
  }, "Option 1: for loop"), "\n", React.createElement(_components.p, null, "We keep track of a ", React.createElement(_components.code, null, "sum"), " variable while iterating through the cards."), "\n", React.createElement(_components.p, null, "For each card, we determine how many winning numbers match with the numbers we have."), "\n", React.createElement(_components.p, null, "Using the amount of matches, we calculate the card’s score."), "\n", React.createElement(_components.p, null, "If there are 0 matches, the score is 0.\nIf the score is higher, the score is a power of 2 thanks to that doubling logic."), "\n", React.createElement(_components.p, null, "For each card, we add that score to the sum."), "\n", React.createElement(_components.h4, {
    id: "code"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_1(input: &str) -> u32 {\n    let mut sum = 0;\n    for line in input.lines() {\n        let (_, numbers) = line.split_once(\": \").unwrap();\n        let (winning, holding) = numbers.split_once(\"|\").unwrap();\n        let winning = winning.split_whitespace();\n        let holding: Vec<_> = holding.split_whitespace().collect();\n        let num_winners = winning.filter(|s| holding.contains(s)).count() as u32;\n        let score = match num_winners {\n            0 => 0,\n            n => 2u32.pow(n - 1),\n        };\n        sum += score;\n    }\n    sum\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-an-iterator-chain"
  }, "Option 2: An iterator chain"), "\n", React.createElement(_components.p, null, "Same idea, but we no longer explicitly keep track of a sum.\nWe transform each line into a score, and call ", React.createElement(_components.code, null, ".sum()"), " at the end of the chain to sum all those scores."), "\n", React.createElement(_components.h4, {
    id: "code-1"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_1(input: &str) -> u32 {\n    input\n        .lines()\n        .map(|line| {\n            let (_, numbers) = line.split_once(\": \").unwrap();\n            let (winning, holding) = numbers.split_once(\" | \").unwrap();\n            let winning = winning.split_whitespace();\n            let holding: Vec<_> = holding.split_whitespace().collect();\n            let num_winners = winning.filter(|s| holding.contains(s)).count();\n            match num_winners {\n                0 => 0,\n                n => 2u32.pow(n as u32 - 1),\n            }\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "part-2"
  }, "Part 2"), "\n", React.createElement(_components.p, null, "Turns out the rules were different!"), "\n", React.createElement(_components.p, null, "Each scratchcard can cause you to win more scratchcards (how useful)."), "\n", React.createElement(_components.p, null, "You win scratchcards equal to the amount of winning numbers your numbers match."), "\n", React.createElement(_components.p, null, "So in the example where the first scratchcard had 4 winning numbers, you would win 4 extra sratchcards."), "\n", React.createElement(_components.p, null, "You win one copy each of the cards numbers after your winning card.\nSo in the example, card 1 won 4 extra cards:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "a copy of card 2"), "\n", React.createElement(_components.li, null, "a copy of card 3"), "\n", React.createElement(_components.li, null, "a copy of card 4"), "\n", React.createElement(_components.li, null, "a copy of card 5"), "\n"), "\n", React.createElement(_components.p, null, "The question asks how many cards we end up with when no more cards are won."), "\n", React.createElement(_components.h3, {
    id: "option-1-for-loop-1"
  }, "Option 1: for loop"), "\n", React.createElement(_components.p, null, "We keep track of a ", React.createElement(_components.code, null, "counts"), " list that tells us how many cards of each type we have.\nIt starts off filled with ones, because we start the game with one card of each type."), "\n", React.createElement(_components.p, null, "We loop through every card in the input.\nFor every card, we calculate the amount of matches."), "\n", React.createElement(_components.p, null, "For every match, the card count of the card we win gets incremented."), "\n", React.createElement(_components.p, null, "Not by 1, but by the total amount of cards we have of the current card.\n(Each one of those will win 1 card, so all of them together wins “current card amount”.)"), "\n", React.createElement(_components.p, null, "At the end, we sum all card amounts in that list we kept track of."), "\n", React.createElement(_components.h4, {
    id: "code-2"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_2(input: &str) -> u32 {\n    let mut counts = vec![1; input.lines().count()];\n\n    for (idx, line) in input.lines().enumerate() {\n        let (_, numbers) = line.split_once(\": \").unwrap();\n        let (winning, holding) = numbers.split_once(\"|\").unwrap();\n        let winning = winning.split_whitespace();\n        let holding: Vec<_> = holding.split_whitespace().collect();\n        let num_winners = winning.filter(|s| holding.contains(s)).count();\n\n        // update the card counts for every card we win\n        let num_cards = counts[idx];\n        for i in (idx + 1)..=(idx + num_winners) {\n            counts[i] += num_cards;\n        }\n    }\n\n    counts.iter().sum()\n}\n")), "\n", React.createElement(_components.h3, {
    id: "option-2-an-iterator-chain-1"
  }, "Option 2: An iterator chain"), "\n", React.createElement(_components.p, null, "We iterate through every card.\nEach item in our iterator chain is transformed to the amount of those cards we have when the iteration ends.\nAt the end, these amounts are summed together."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.code, null, ".scan"), " iterator adapter keeps track of the counts of every card."), "\n", React.createElement(Aside, {
    variant: "info"
  }, React.createElement(_components.p, null, "A card can only win next cards, never previous ones.")), "\n", React.createElement(_components.h4, {
    id: "code-3"
  }, "Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs"
  }, "pub fn part_2(input: &str) -> u32 {\n    input\n        .lines()\n        .enumerate()\n        .scan(vec![1; input.lines().count()], |counts, (idx, line)| {\n            let (_, numbers) = line.split_once(\": \").unwrap();\n            let (winning, holding) = numbers.split_once(\"|\").unwrap();\n            let winning = winning.split_whitespace();\n            let holding: Vec<_> = holding.split_whitespace().collect();\n            let num_winners = winning.filter(|s| holding.contains(s)).count();\n\n            // update the card counts for every card we win\n            let num_cards = counts[idx];\n            for i in idx + 1..=idx + num_winners {\n                counts[i] += num_cards;\n            }\n\n            Some(num_cards)\n        })\n        .sum()\n}\n")), "\n", React.createElement(_components.h2, {
    id: "final-code"
  }, "Final code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust",
    title: "day_04.rs",
    numberLines: true
  }, "pub fn part_1(input: &str) -> u32 {\n    input\n        .lines()\n        .map(|line| {\n            let (_, numbers) = line.split_once(\": \").unwrap();\n            let (winning, holding) = numbers.split_once(\" | \").unwrap();\n            let winning = winning.split_whitespace();\n            let holding: Vec<_> = holding.split_whitespace().collect();\n            let num_winners = winning.filter(|s| holding.contains(s)).count();\n\n            match num_winners {\n                0 => 0,\n                n => 2u32.pow(n as u32 - 1),\n            }\n        })\n        .sum()\n}\n\npub fn part_2(input: &str) -> u32 {\n    input\n        .lines()\n        .enumerate()\n        .scan(vec![1; input.lines().count()], |counts, (idx, line)| {\n            let (_, numbers) = line.split_once(\": \").unwrap();\n            let (winning, holding) = numbers.split_once(\"|\").unwrap();\n            let winning = winning.split_whitespace();\n            let holding: Vec<_> = holding.split_whitespace().collect();\n            let num_winners = winning.filter(|s| holding.contains(s)).count();\n\n            let num_cards = counts[idx];\n            for i in idx + 1..=idx + num_winners {\n                counts[i] += num_cards;\n            }\n\n            Some(num_cards)\n        })\n        .sum()\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
